/**
 * Vue Router - Routes
 */

// Views
const Main = () => import(/* webpackChunkName: "main" */ '@/views/Main')

// Partials
const Home = () => import(/* webpackChunkName: "main" */ '@/views/partials/Home')
const MikeParsons = () => import('@/views/partials/MikeParsons')
const GabrielBerrios = () => import('@/views/partials/GabrielBerrios')
const KurtisTodd = () => import('@/views/partials/KurtisTodd')
const DerekRaulerson = () => import('@/views/partials/DerekRaulerson')
const RyanConiglario = () => import('@/views/partials/RyanConiglario')
//const MerandaCampbell = () => import('@/views/partials/MerandaCampbell')
//const ElijahRamos = () => import('@/views/partials/ElijahRamos')
const Artist = () => import('@/views/partials/Artist')
const ArtistList = () => import('@/views/partials/ArtistList')

// Components
const SiteFooter = () => import(/* webpackChunkName: "main" */ '@/components/SiteFooter')
const Hero = () => import(/* webpackChunkName: "main" */ '@/components/Hero')

// Components
const MainComponents = {
	hero: Hero,
	footer: SiteFooter,
}

// Routers
const routes = [
	{
		path: '',
		component: Main,
		children: [
			{
				path: '',
				name: 'home',
				components: Object.assign({}, MainComponents, {
					content: Home,
				}),
				meta: {
					title: 'Mike Parsons Ink'
				},
			},
			{
				path: '/mike-parsons',
				name: 'mikeparsons',
				components: Object.assign({}, MainComponents, {
					content: MikeParsons,
				}),
				meta: {
					title: 'Mike Parsons Portfolio'
				},
			},
			{
				path: '/gabriel-berrios',
				// name: 'gabrielberrios',
				// components: Object.assign({}, MainComponents, {
				// 	content: GabrielBerrios,
				// }),
				// meta: {
				// 	title: 'Gabriel Berrios Portfolio'
				// },
				redirect: { name: 'artists' }
			},
			{
				path: '/kurtis-todd',
				name: 'kurtistodd',
				components: Object.assign({}, MainComponents, {
					content: KurtisTodd,
				}),
				meta: {
					title: 'Kurtis Todd Portfolio'
				},
			},
			{
				path: '/derek-raulerson',
				name: 'derekraulerson',
				components: Object.assign({}, MainComponents, {
					content: DerekRaulerson,
				}),
				meta: {
					title: 'Derek Raulerson Portfolio'
				},
			},
			{
				path: '/ryan-coniglario',
				name: 'ryanconiglario',
				components: Object.assign({}, MainComponents, {
					content: RyanConiglario,
				}),
				meta: {
					title: 'Ryan Coniglario Portfolio'
				},
			},
			{
				path: '/meranda-campbell',
				// name: 'merandacampbell',
				// components: Object.assign({}, MainComponents, {
				// 	content: MerandaCampbell,
				// }),
				// meta: {
				// 	title: 'Meranda Campbell Portfolio'
				// },
				redirect: { name: 'artists' }
			},
			
			{
				path: '/elijah-ramos',
				// name: 'elijahramos',
				// components: Object.assign({}, MainComponents, {
				// 	content: ElijahRamos,
				// }),
				// meta: {
				// 	title: 'Elijah Ramos Portfolio'
				// },
				redirect: { name: 'artists' }
			},
			{
				path: 'artist',
				name: 'artist',
				components: Object.assign({}, MainComponents, {
					content: Artist,
				}),
				meta: {
					title: 'Artist'
				},
			},
			{
				path: 'artists',
				name: 'artists',
				components: Object.assign({}, {
					content: ArtistList,
				}),
				meta: {
					title: 'Artists'
				},
			},
		],
	},
]

export default routes
